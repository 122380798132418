<template>
  <div
    class="app-content"
  >
    <div class="app-hero">
      <BlockHero
        v-if="hasHero && heroName === 'BlockHero'"
        :data="data?.story?.content?.hero[0]"
      />
      <BlockHeroBlog
        v-if="hasHero && heroName === 'BlockHeroBlog'"
        :data="data?.story?.content?.hero[0]"
      />
      <BlockHeroEvents
        v-if="hasHero && heroName === 'BlockHeroEvents'"
        :data="data?.story?.content?.hero[0]"
      />
      <BlockHeroConsultations
        v-if="hasHero && heroName === 'BlockHeroConsultations'"
        :data="data?.story?.content?.hero[0]"
      />
    </div>

    <AppBreadcrumbs
      :class="{
        'no-hero': !hasHero,
        'has-padding': !hasHero,
        'has-small-padding': data?.story?.content?.has_padding_small,
        'is-hidden': data?.story?.content?.hide_breadcrumb,
      }"
    />
    <AppComponentRenderer
      v-if="data?.story?.content?.main_content"
      :data="data.story.content.main_content"
      tag="main"
      class="app-main-content"
      :class="{
        'has-padding': !hasHero,
      }"
    />
  </div>
</template>

<script setup>

/*
  Storyblok data
*/
const storyStore = useStoryStore();
const route = useRoute();
const basePath = route.path;
const path = basePath === '/' || basePath === '' ? '/home' : basePath;

const { error } = await useAsyncData(
    `story-${path}`, // cache key for this request
    () => storyStore.fetchCurrentStory(),
);

if (error.value) {
    throw createError({
        statusCode: 404,
        message: 'Page not found',
        fatal: true,
    });
}

const data = ref(storyStore.getCurrentStory);

onMounted(() => {
    // If version is draft, load the bridge to support storybloks preview
    const version = route.query._storyblok ? 'draft' : 'published';

    if (version === 'draft') {
        useStoryblokBridge(data.value.story.id, (evStory) => {
            data.value.story = evStory;
        });
    }
});

/*
    Check if the page has a hero
*/
const hasHero = computed(() => {
    const heroContent = data?.value?.story?.content?.hero;
    return heroContent && heroContent.length > 0;
});

const heroName = computed(() => {
    const heroContent = data?.value?.story?.content?.hero;
    return heroContent && heroContent[0]?.component;
});

/*
  SEO
*/
const { setData } = useSEO();

if (data?.value.story.content.seo) {
    setData(data?.value.story.content.seo);
}

const { dayjs } = useDayJs();
const runtimeConfig = useRuntimeConfig();
if (hasHero.value === true && heroName.value === 'BlockHeroEvents') {
    const event = computed(() => storyStore.getConnectedStrapiData);
    const eventData = event.value;
    const dates = eventData?.dates?.filter((date) => {
        const now = dayjs();
        return dayjs(date.date).isAfter(now);
    });

    if (Array.isArray(dates) && dates.length > 0) {
        useJsonld({
            '@context': 'https://schema.org',
            '@type': 'Event',
            name: eventData?.title,
            startDate: dates[0]?.date,
            endDate: dates[0]?.end_date,
            eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
            eventStatus: 'https://schema.org/EventScheduled',
            image: eventData?.teaser_image?.url,
            description: eventData?.ics_description,
            offers: {
                '@type': 'Offer',
                url: `${runtimeConfig.public.BASE_URL}/${eventData?.sb_slug}`,
                price: eventData?.price || 0,
                priceCurrency: 'CHF',
            },
            performer: {
                '@type': 'PerformingGroup',
                name: eventData?.speaker?.name,
            },
            organizer: {
                '@type': 'Organization',
                name: 'Saner Health',
                url: runtimeConfig.public.BASE_URL,
            },
        });
    }
}
</script>

<style lang="scss" scoped>
.app-main-content {
    @include grid-layout();

    width: 100%;
    max-width: var(--grid-width);
    padding: 0 var(--page-padding);
    margin: 0 auto;

    @include fluid('margin-bottom', 80px, 120px);

    &.no-margin {
        margin: 0;
    }

    @include tablet-portrait{
        &.has-padding {
            @include fluid('padding-top', 140px, 278px);
        }
    }
}
</style>
